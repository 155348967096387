import * as React from 'react';
import getDistanceFromLatLonInM from '../../utils/Distance';
import BarChart from '../charts/BarChart';
import { Paper } from '@mui/material';
import dayjs from 'dayjs';
export default function StopTimesChart(props){
    const [data, setData] = React.useState([])
    React.useEffect(() => {
        console.log(props.selectedPath.length )
        console.log(props.stops )
        if(props.selectedPath.length > 0 && props.stops.length > 0){
            let data = []
            let selectedPathWithStops = new Map(props.stops.map(s => [s.stop_name, []]));
            console.log(selectedPathWithStops)
            props.selectedPath.filter(el => el.speed < 1).forEach(el => {
                let distances = new Map()
                props.stops.forEach( s => {
                    let distance = getDistanceFromLatLonInM(el.latitude, el.longitude, s.stop_lat, s.stop_lon)
                    distances.set(s.stop_name, distance)
                })
                
                const mapSort = new Map([...distances.entries()].sort((a, b) => a[1] - b[1]));
                let distance = [...mapSort.values()][0]
                if(distance < 50){ //meno di 50 metri di distanza con la fermata
                    let stop_name =  [...mapSort.keys()][0]
                    let array = selectedPathWithStops.get(stop_name)
                    array.push(el)
                    selectedPathWithStops.set(stop_name, array)
                } 
                
            })
            console.log(selectedPathWithStops)
    
            for (const entry of selectedPathWithStops.entries()) {
                let [key, value] = entry
                let seconds = 0
                if(value.length > 0){
                    seconds = dayjs(value[value.length-1].dateTimestamp).diff(value[0].dateTimestamp, 'second')
                }
                data.push({
                    "stop_id": key,
                    "secondi": seconds
                })
              }
              console.log(data) 
              setData(data)  
        }
         
    }, [props.selectedPath, props.stops])
    return (
        <Paper
            sx={{
                p: 2,
                display: 'flex',
                flexDirection: 'column',
                height: 410,
            }}
        >
            <BarChart
                title="Secondi per fermata"
                data={data}
                dataKeyX="stop_id"
                dataKeyY="secondi"
                />
        </Paper>
    )
}