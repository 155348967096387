import './App.css';
import { useAuth0 } from "@auth0/auth0-react";
import { BrowserRouter as Router, Route, Routes, Outlet} from 'react-router-dom';
import * as React from "react";
import Homepage from './components/homepage/Homepage';
import Footer from './components/footer/Footer';
import Layout from './components/Layout';
import {UserProvider} from "./components/UserProvider";
import PathPage from './components/pathPage/PathPage';
import DashboardPage from './components/dashBoardPage/DashboardPage';
//import BusPage from './components/busPage/BusPage';
import AccountPage from './components/accountPage/AccountPage';

const RoutedLayout = () => (
    // nested routes render out in Outlet component
    <Layout>
      <Outlet/>
    </Layout>
  );

function App() {
    const { isAuthenticated } = useAuth0();
      
    return (
        <UserProvider>
            <Router>
                <Routes>
                    {isAuthenticated && 
                    <Route element={<RoutedLayout/>}>
                        <Route exact path="/dashboard" element={ <DashboardPage/> }/>
                        <Route exact path="/linee" element={ <PathPage/>}/>
                        <Route exact path="/vetture" element={ <PathPage/>}/>
                        <Route exact path="/account" element={ <AccountPage/>}/>
                    </Route>
                    }
                    <Route exact path="/" element={ <Homepage/> }/>
                </Routes>
                <Footer/>
            </Router>
        </UserProvider>
        )
}

export default App;
