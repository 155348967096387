import {Grid, Typography} from "@mui/material";
import * as React from "react";
import {useEffect, useState} from "react";
import API from '../../API';
import FilterTool from "./filterTool/FilterTool";
import DataTable from "./DataTable";
import StopTimesChart from "./StopTimesChart";
import Map from './Map';
import SpeedChart from "./SpeedChart";
import dayjs from "dayjs";
import TripInformation from "./TripInformation";
import Loading from "../../utils/Loading";
export default function PathPage(){
    const [currentTrip, setCurrentTrip] = useState('')
    const [center, setCenter] = useState([40.725925, 8.555683])
    const [paths, setPaths] = useState([])
    const [currentTripShape, setCurrentTripShape] = useState([])
    const [selectedPath, setSelectedPath] = useState([])
    const [stops, setStops] = useState([])
    const [busIds, setBusIds] = useState([])
    const [filterDate, setFilterDate] = useState(dayjs("2022-04-18"))
    const [singleDate, setSingleDate] = useState(true)
    const [dirty, setDirty] = useState(false)
    const [distances, setDistances] = useState([])
    const [totalAverageDistance, setTotalAverageDistance] = useState(0)

    useEffect(() => {
        if(dirty){
            setSelectedPath([])
            getTripShape();
            getTripStopTime();
            getTripPaths();
        }
    }, [dirty]);

    const getTripShape = () => {
        //get shape of the current trip
        API.getShape(currentTrip).then((data) => {
            let positions = []
            data.forEach( d => {
                const { shape_pt_lat, shape_pt_lon, shape_pt_sequence } = d
                positions.push({"shape_pt_lat":parseFloat(shape_pt_lat), "shape_pt_lon":parseFloat(shape_pt_lon), "shape_pt_sequence":parseInt(shape_pt_sequence)})               
            })
            positions.sort((a,b) => a.shape_pt_sequence - b.shape_pt_sequence)
            const p = positions.map((v) => [v.shape_pt_lat, v.shape_pt_lon])
            console.log(p)
            const centerLat = (parseFloat(data[0].shape_pt_lat) + parseFloat(data[data.length-1].shape_pt_lat))/2
            const centerLon = (parseFloat(data[0].shape_pt_lon) + parseFloat(data[data.length-1].shape_pt_lon))/2
            setCenter([centerLat, centerLon])
            setCurrentTripShape(p)
        }).catch(err => console.log(err)); 
      }

      const getTripStopTime = () => {
          //get stop times of the current trip
          API.getStopTimes(currentTrip).then((data) => {
            setStops(data)
         }).catch(err => console.log(err)); 
      }

      const getTripPaths = () => {
        let paths = [];
        let busIds = new Set();
        let selectedDate = singleDate === false ? "" : filterDate.format('YYYY-MM-DD')

        //get paths for the current trip
        API.getPaths(currentTrip, selectedDate).then((data) => {
            data.forEach( d => {
                let array = paths[d.sequence_number] || []
                if (array.length === 0)
                    paths[d.sequence_number] = []

                paths[d.sequence_number].push({'busId': d.bus_id, 'dateTimestamp': d.date_timestamp, 'latitude': d.latitude, 'longitude': d.longitude, 'sequenceNumber': d.sequence_number, 'distance':d.distance_m, 'speed':d.speed })
                paths[d.sequence_number].sort((a,b) => a.dateTimestamp - b.dateTimestamp)
            })
            console.log(paths)
            setPaths(paths)
            
            //get all the buses that made the trip
            paths.forEach( p => {
                busIds.add(p[0].busId)
            })
            setBusIds(busIds)

            //get the average distance 
            API.getDistances(currentTrip, selectedDate).then((data) => {
                setDistances(data)
                const sum = data.reduce(function (acc, obj) { return acc + obj.totalDistance; }, 0);
                const avg = (sum / data.length).toFixed(2) || 0;
                setTotalAverageDistance(avg)
                setDirty(false)
                //setLoading(false)
             }).catch(err => console.log(err)); 
        })
        .catch(err => console.log(err))
      }

    return(
        <Grid container spacing={3}>
            <Grid item xs={9}>
                <Map 
                    currentTripShape={currentTripShape}
                    stops={stops}
                    center={center}
                    selectedPath={selectedPath}/>
            </Grid>
            <Grid item xs={3}>
                <FilterTool 
                    setCurrentTrip={setCurrentTrip}
                    setDirty={setDirty}
                    singleDate={singleDate}
                    setSingleDate={setSingleDate}
                    filterDate={filterDate}
                    setFilterDate={setFilterDate}
                  />
            </Grid>

            {dirty ? 
            <Loading loading={dirty}/> 
            :
            currentTrip !== '' && <>
                <Grid item xs={12}>
                    <Typography style={{ margin: '8px'}} variant="h5" component="div">
                        {paths.length === 0 ? `Nessun dato registrato per la linea ${currentTrip}` : `Risultati della linea ${currentTrip}`}
                    </Typography>
                    {paths.length > 0 && <>
                        <TripInformation 
                            busIds={busIds}
                            totalAverageDistance={totalAverageDistance}/>
                        <Typography style={{ margin: '10px'}} variant="body1" component="div">
                            Percorsi:
                        </Typography>
                        <DataTable 
                            paths={paths}
                            currentTrip={currentTrip}
                            setSelectedPath={setSelectedPath}
                            distances={distances}/>
                    </>
                    }
                </Grid>

                {selectedPath.length > 0 && <>
                    <Grid item xs={12}>
                        <SpeedChart 
                            selectedPath={selectedPath}/>
                    </Grid>
                    <Grid item xs={12}>
                        <StopTimesChart 
                            selectedPath={selectedPath}
                            stops={stops}/>
                    </Grid>
                    </>}
            
            </>}
            
        </Grid>
    )
}
