import { useAuth0 } from "@auth0/auth0-react";
import React from "react";
import { Button } from "@mui/material";

export const SignupButton = () => {
  const { loginWithRedirect } = useAuth0();

  const handleSignUp = async () => {
    await loginWithRedirect({
      prompt: "login",
      screen_hint: "signup",
      appState: {
        returnTo: "/dashboard",
      },
    });
  };

  return (
    <Button 
        size="big"
        variant="outlined"
        style={{minWidth: "10vw"}}
        onClick={handleSignUp}
    >
        Registrati
    </Button>
  );
};

