/**
 * All the API calls
 */

const BASEURL = '/api';

function getJson(httpResponsePromise) {
    return new Promise((resolve, reject) => {
        httpResponsePromise
            .then((response) => {
                if (response.ok) {

                    // always return {} from server, never null or non json, otherwise it will fail
                    response.json()
                        .then( json => resolve(json) )
                        .catch( err => reject({ error: "Cannot parse server response" }))

                } else {
                    // analyze the cause of error
                    response.json()
                        .then(obj => reject(obj)) // error msg in the response body
                        .catch(err => reject({ error: response.statusText}))
                }
            })
            .catch(err => reject({ error: "Cannot communicate with server"  })) // connection error
    });
}

async function getShapes() {
    return getJson(
        fetch(BASEURL + '/shapes')
    )
}

async function getShape(shapeId) {
    return getJson(
        fetch(BASEURL + '/shapes/' + shapeId)
    )
}

async function getTripsName() {
    return getJson(
        fetch(BASEURL + '/trips/names')
    )
}

async function getPaths(tripId, date) {
    if(date === "")
        return getJson(
            fetch(BASEURL + '/paths/trips/' + tripId)
        )
    else
        return getJson(
            fetch(BASEURL + '/paths/trips/' + tripId + '/' + date)
        )
}

async function getDistances(tripId, date) {
    if(date === "")
        return getJson(
            fetch(BASEURL + '/distances/trips/' + tripId)
        )
    else
        return getJson(
            fetch(BASEURL + '/distances/trips/' + tripId + '/' + date)
        )
}

async function getStopTimes(shapeId) {
    return getJson(
        fetch(BASEURL + '/shapes/stopTimes/' + shapeId)
    )
}

async function getTotalDistances() {
    return getJson(
        fetch(BASEURL + '/distances')
    )
}

async function getBusesNumber() {
    return getJson(
        fetch(BASEURL + '/buses')
    )
}

async function getPathsNumber() {
    return getJson(
        fetch(BASEURL + '/paths')
    )
}

async function getTripsNumber() {
    return getJson(
        fetch(BASEURL + '/trips')
    )
}

async function getDaysNumber() {
    return getJson(
        fetch(BASEURL + '/days')
    )
}

const API = {getShapes, getShape, getStopTimes, getPaths, getDistances, getTripsName, getBusesNumber, getTotalDistances, getPathsNumber, getTripsNumber, getDaysNumber};
export default API;