import { BarChart as BarChartRe, Bar, Cell, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import Title from '../Title';
import * as React from 'react';


export default function BarChart(props){
    return(
        <React.Fragment>
            <Title>{props.title}</Title>
            <ResponsiveContainer>
                <BarChartRe
                    width={500}
                    height={100}
                    data={props.data}
                    margin={{
                        top: 5,
                        right: 75,
                        left: 20,
                        bottom: 140,
                    }}
                >
                    <CartesianGrid stroke="#ccc" strokeDasharray="5 5"/>
                    <XAxis dataKey={props.dataKeyX} interval={0} angle={45} dx={50} dy={50}/>
                    <YAxis />
                    <Tooltip />
                    <Legend verticalAlign="top" height={36}/>
                    <Bar dataKey={props.dataKeyY} fill="#2196f3"/>
                </BarChartRe>
            </ResponsiveContainer>
        </React.Fragment>
    );
}
