import * as React from 'react';
import { styled, useTheme } from '@mui/material/styles';
import {Box, List, Divider, IconButton, ListItemButton, ListItemIcon, ListItemText} from '@mui/material';
import MuiDrawer from '@mui/material/Drawer';
import MuiAppBar from '@mui/material/AppBar';
//icons
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import DashboardRoundedIcon from '@mui/icons-material/DashboardRounded';
import RouteRoundedIcon from '@mui/icons-material/RouteRounded';
import DirectionsBusFilledRoundedIcon from '@mui/icons-material/DirectionsBusFilledRounded';

import NavBar from "./NavBar";
import { useNavigate, useLocation } from "react-router-dom"

const drawerWidth = 240;

const openedMixin = (theme) => ({
    width: drawerWidth,
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: 'hidden',
});

const closedMixin = (theme) => ({
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: `calc(${theme.spacing(7)} + 1px)`,
    [theme.breakpoints.up('sm')]: {
        width: `calc(${theme.spacing(8)} + 1px)`,
    },
});

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
    ({ theme, open }) => ({
        width: drawerWidth,
        flexShrink: 0,
        whiteSpace: 'nowrap',
        boxSizing: 'border-box',
        ...(open && {
            ...openedMixin(theme),
            '& .MuiDrawer-paper': openedMixin(theme),
        }),
        ...(!open && {
            ...closedMixin(theme),
            '& .MuiDrawer-paper': closedMixin(theme),
        }),
    }),
);


const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
}));

const BoxNavbar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    }),
}));

export default function Layout({ children }) {
    const theme = useTheme();
    const [open, setOpen] = React.useState(false);
    //const location = useLocation();
    const [title, setTitle] = React.useState("Dashboard");

    const handleDrawerOpen = () => {
        setOpen(true);
    };

    const handleDrawerClose = () => {
        setOpen(false);
    };

    return (
        <Box sx={{ display: 'flex' }}>
            <BoxNavbar
                position="fixed"
                open={open}
            >
                <NavBar
                    handleDrawerOpen={handleDrawerOpen}
                    title={title}
                    setTitle={setTitle}
                />
            </BoxNavbar>

            <Drawer
                variant="permanent"
                open={open}
            >
                <DrawerHeader>
                    <IconButton
                        onClick={handleDrawerClose}
                    >
                        {theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />}
                    </IconButton>
                </DrawerHeader>
                <Divider />
                <DrawerMenu
                    setTitle={setTitle}
                    open={open}
                    handleDrawerClose={handleDrawerClose}/>
            </Drawer>

            {/* main content */}
            <Box component="main" sx={{ flexGrow: 1, px: 2.5, py: 10 }}>
                {children}
            </Box>
        </Box>
    );
}


function DrawerMenu(props){
    const navigate = useNavigate()

    const handleClick = (location, title) => {
        navigate(`/${location}`);
        props.setTitle(`${title}`);
        props.handleDrawerClose();
    }

    const itemsList = [
        {
            text: "Dashboard",
            icon: <DashboardRoundedIcon/>,
            onClick: () => handleClick('dashboard', 'Dashboard')
        },
        {
            text: "Analizza linee",
            icon: <RouteRoundedIcon/>,
            onClick: () => handleClick('linee', 'Analizza Linee')
        },
        {
            text: "Analizza vetture",
            icon: <DirectionsBusFilledRoundedIcon/>,
            onClick: () => handleClick('vetture', 'Analizza vetture')
        },
    ]
    return(
        <List>
            {itemsList.map((item) => {
                const {text, icon, onClick} = item;
                return (
                    <ListItemButton
                        key={text}
                        onClick={onClick}
                        sx={{
                            minHeight: 48,
                            justifyContent: props.open ? 'initial' : 'center',
                            px: 2.5,
                        }}
                    >
                        <ListItemIcon
                            sx={{
                                minWidth: 0,
                                mr: props.open ? 3 : 'auto',
                                justifyContent: 'center',
                            }}
                        >
                            {icon}
                        </ListItemIcon>
                        <ListItemText
                            primary={text}
                            sx={{ opacity: props.open ? 1 : 0 }}
                        />
                    </ListItemButton>
                )
            })
            }
        </List>
    )
}