import { DataGrid } from '@mui/x-data-grid';
import {useEffect, useState} from "react";
import Typography from "@mui/material/Typography";
import dayjs from 'dayjs';

const columns = [
    { field: 'id', headerName: 'ID', width: 100, flex: 1},
    { field: 'busId', headerName: 'Bus ID', width: 130, sortable: false, flex: 1},
    { field: 'startDate', headerName: 'Inizio Corsa', width: 250, flex: 1},
    { field: 'endDate', headerName: 'Fine Corsa', width: 250, flex: 1},
    { field: 'distance', headerName: 'Distanza percorsa', width: 250, flex: 1},
    { field: 'averageSpeed', headerName: 'Velocità media', width: 250, flex: 1},
];

export default function DataTable(props) {
    const [rowsMap, setRowsMap] = useState(new Map());
    const [selectionModel, setSelectionModel] = useState([]);

    useEffect(()=> {
        if(props.paths.length > 0 && props.distances.length > 0){
            const filteredPath = props.paths.map( path => {
                const intervalTime = (dayjs(path[path.length-1].dateTimestamp).diff(dayjs(path[0].dateTimestamp), 'seconds'))/3600
                const distance = props.distances.filter(el => el.sequence_number === path[0].sequenceNumber)[0].totalDistance.toFixed(2)
                const averageSpeed = (distance/intervalTime).toFixed(2)
                
                return {
                    "id": path[0].sequenceNumber,
                    "busId": path[0].busId,
                    "startDate": `${path[0].dateTimestamp.substr(0, 10)} ${path[0].dateTimestamp.substr(11, 8)}`,
                    "endDate": `${path[path.length-1].dateTimestamp.substr(0, 10)} ${path[path.length-1].dateTimestamp.substr(11, 8)}`,
                    "distance": distance.toString() + " km",
                    "averageSpeed": averageSpeed.toString() + " km/h"
                }
            })
            console.log(filteredPath)
            let filteredPathMap = new Map()
            filteredPath.forEach( path => {
                let date = path.startDate.substr(0, 10)
                let array = []
                if(filteredPathMap.has(date)){
                    array = filteredPathMap.get(date)
                }
                array.push({
                    "id": path.id,
                    "busId": path.busId,
                    "startDate": `${path.startDate.substr(11, 8)}`,
                    "endDate": `${path.endDate.substr(11, 8)}`,
                    "distance": path.distance,
                    "averageSpeed": path.averageSpeed
                })
                filteredPathMap.set(date, array)
            })
            console.log(filteredPathMap)
            setRowsMap(filteredPathMap)
        }
    }, [props.paths, props.distances]);

    return (
        <div style={{borderRadius: '10px', backgroundColor:'#cfd8dc', padding:'8px'}}>
            {[...rowsMap.keys()].map(key => {
                return (
                    <div key={key}>
                        <Typography style={{ margin: '8px'}} variant="button" component="div">
                            <b>{key}</b>
                        </Typography>
                        <div style={{height: 318, width: '100%', margin: 'auto', backgroundColor:'#eceff1', borderRadius: '5px'}}>
                            <DataGrid
                                rows={rowsMap.get(key)}
                                columns={columns}
                                pageSize={5}
                                checkboxSelection
                                selectionModel={selectionModel}
                                hideFooterSelectedRowCount
                                rowsPerPageOptions={[5]}
                                onSelectionModelChange={(selection) => {
                                    let result = []
                                    if (selection.length > 1) {
                                        const selectionSet = new Set(selectionModel);
                                        result = selection.filter((s) => !selectionSet.has(s));
                                        } else {
                                        result = selection;
                                        }
                                    setSelectionModel(result);
                                    result.length > 0 ? props.setSelectedPath(props.paths[result[0]]) :  props.setSelectedPath([])
                                    window.scrollTo({
                                        top: 0,
                                        behavior: 'smooth',
                                    });
                                }}
                            />
                        </div>
                    </div>
                )
            })
        }
        </div>
       
    )
}