import './Footer.css';
import Box from "@mui/material/Box";
import {Container, Link} from "@mui/material";
import Typography from "@mui/material/Typography";

export default function Footer(){
    return (
        <Box
            component="footer"
            sx={{
                px: 2,
                mt: 'auto',
                color: '#E5E7EB'
            }}
        >
            <Container maxWidth="sm">
                <Typography align="center" sx={{pt: 3}}>
                    Designed and developed by
                    <Link href="https://abinsula.com/" color="#E5E7EB">
                        {' Abinsula '}
                    </Link>
                    @2022
                </Typography>
            </Container>
        </Box>
    )
}

