// material-ui
import { Grid, Stack, Typography } from '@mui/material';

// project import
import MainCard from './MainCard';

// external libraries
import CountUp from 'react-countup';

// ==============================|| STATISTICS ||============================== //

const StatisticCard = ({ title, count, imagePath }) => (
    <MainCard contentSX={{ p: 2.25 }}>
        <Stack spacing={0.5}>
            <Typography variant="h6" color="textSecondary">
                {title}
            </Typography>
            <br/><br/>
            <Grid container alignItems="center">
                <Grid item xs={12} md={9}>
                    <Typography variant="h2" color="rgb(55, 111, 208)">
                        <CountUp
                            end={count}
                            duration={1}/>
                    </Typography>
                </Grid>
               
                {imagePath && (
                    <Grid item xs={12} md={3}>
                        <img src={imagePath} alt="Linea autobus"/>
                    </Grid>
                )}
                {/*percentage && (
                    <Grid item>
                        <Chip
                            variant="combined"
                            color={color}
                            icon={
                                <>
                                    {!isLoss && <FaceOutlined style={{ fontSize: '0.75rem', color: 'inherit' }} />}
                                    {isLoss && <FaceOutlined style={{ fontSize: '0.75rem', color: 'inherit' }} />}
                                </>
                            }
                            label={`${percentage}%`}
                            sx={{ ml: 1.25, pl: 1 }}
                            size="small"
                        />
                    </Grid>
                        )*/}
            </Grid>
        </Stack>
    </MainCard>
);

export default StatisticCard;