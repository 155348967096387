import * as React from 'react';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { SignupButton } from '../authentication/buttons/SignupButton';
import { LoginButton } from '../authentication/buttons/LoginButton';
export default function OutlinedCard() {
    return (
        <Box>
            <Card variant="outlined" style={{ border: '4px solid #1F2952', borderRadius: '10px 10px 10px 10px'}}>
                <CardContent style={{minWidth:"20vw", minHeight:"20vh", textAlign:"center"}}>
                    <Typography sx={{ fontSize: 25 }}  gutterBottom>
                        Benvenuto su ATPSassari analytics
                    </Typography>
                    <Typography sx={{ marginTop: 3 }} color="text.secondary" component="div">
                        Il miglior modo per analizzare i tuoi dati
                    </Typography>
                </CardContent>
                <CardActions style={{justifyContent: "space-around", marginBottom: "0.5rem"}}>
                    <LoginButton />
                    <SignupButton />
                </CardActions>
            </Card>
        </Box>
    );
}