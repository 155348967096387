import {Toolbar, Typography, IconButton, MenuItem, MenuList, Popover, Box, Avatar}  from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import {useState} from 'react';
import { useAuth0 } from "@auth0/auth0-react";
import { LogoutButton } from './authentication/buttons/LogoutButton';
import { useNavigate } from 'react-router-dom';


export default function NavBar(props) {
    const { user, isAuthenticated } = useAuth0();
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const id = open ? 'popover' : undefined;

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
      };

    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <Toolbar sx={{backgroundColor: "#1F2952"}}>
            <IconButton
                size="large"
                edge="start"
                color="inherit"
                aria-label="menu"
                sx={{ mr: 2 }}
                onClick={props.handleDrawerOpen}
            >
                <MenuIcon />
            </IconButton>
           
            <Typography
                variant="h6"
                component="h1"
                sx={{ flexGrow: 1 }}
            >
                {props.title}
            </Typography>
            {isAuthenticated && (
                <>
                <Avatar
                    aria-describedby={id}
                    onClick={handleClick}
                    sx={{
                     cursor: 'pointer',
                     height: 40,
                     width: 40,
                  
                   }}
                   src={user.picture}
                />
                <MenuAvatar
                    id={id}
                    open={open}
                    anchorEl={anchorEl}
                    handleClose={handleClose}
                    setTitle={props.setTitle}
                    setAnchorEl={setAnchorEl}
                />
                </> 
            )}
        </Toolbar>
    );
}


const MenuAvatar = (props) => {
    const { anchorEl, handleClose, open, id, setTitle, setAnchorEl } = props;
    return (
        <Popover
            id={id}
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
      
            PaperProps={{
            sx: { width: '300px' }
            }}
        >
            <MenuList
                disablePadding
                sx={{
                    '& > *': {
                    '&:first-of-type': {
                        borderBottomColor: 'divider',
                        borderBottomStyle: 'solid',
                        borderBottomWidth: '1px'
                    },
                    padding: '8px 16px'
                    }
                }}
            >
                <Account setTitle={setTitle} setAnchorEl={setAnchorEl}/>
                <LogoutButton/>
            </MenuList>
      </Popover>
    )
}


const Account = (props) => {
    const { user } = useAuth0();
    const navigate = useNavigate();
    const handleProfile = () => {
        props.setTitle("Account")
        props.setAnchorEl(null);
        navigate("/account")
    };
    return (
        <MenuItem onClick={handleProfile}>
            <Box sx={{ py: 1.5}}>
                <Typography variant="overline">
                    Account
                </Typography>
                <Typography
                    color="text.secondary"
                    variant="body2"
                >
                    {user.name}
                </Typography>
            </Box>
        </MenuItem>
    )
}