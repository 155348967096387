import {MapContainer, Polyline, TileLayer, useMap, useMapEvent, CircleMarker, Tooltip as LeafleatTooltip, LayerGroup} from 'react-leaflet'

export default function Map(props){
    const {currentTripShape, stops, center, selectedPath} = props;
    const bluOptions = { color: '#1F2952' }
    const bluShadeOptions = { color: '#797f97' }
    const primaryOptions = { color: '#2196f3' }

    return (
        <MapContainer style={{ "height": "38em"}} center={center} zoom={14} scrollWheelZoom={false}>
            <TileLayer
                attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            />

            <LayerGroup>
                {currentTripShape.length > 0 && 
                <>
                    {stops.map(s => {return (
                    <CircleMarker 
                        center={[s.stop_lat, s.stop_lon]}
                        pathOptions={bluOptions}
                        radius={4}>
                        <LeafleatTooltip sticky>
                            <div>
                                Fermata: {s.stop_name} <br/>
                                Arrivo: {s.arrival_time} <br/>
                                Partenza: {s.departure_time} <br/>
                                Lat: {s.stop_lat} <br/>
                                Lon: {s.stop_lon}
                            </div>
                        </LeafleatTooltip>
                    </CircleMarker>   
                    ) })
                    }
                    <LayerGroup>
                        <Polyline
                        pathOptions={bluShadeOptions}
                        positions={currentTripShape}
                        onMouseOver={e => e.target.openPopup()}
                        onMouseOut={e => e.target.closePopup()}
                        />
                    </LayerGroup>
                    </>
                }

                {selectedPath.length > 0 &&
                    <LayerGroup>
                        <Polyline
                        pathOptions={primaryOptions}
                        positions={selectedPath.map(t => [t.latitude, t.longitude])}
                        />
                    </LayerGroup>
                }
            </LayerGroup>

            <UpdateCoordinates coords={center}/>
            <SetViewOnClick />
        </MapContainer>
    )
}

function UpdateCoordinates({coords}) {
    const map = useMap();
    map.setView(coords, map.getZoom());

    return null;
}

function SetViewOnClick() {
    const map = useMapEvent('click', (e) => {
      map.setView(e.latlng, map.getZoom(), {
        animate: true,
      })
    })
  
    return null
}