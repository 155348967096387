import * as React from 'react';
import {Container, Grid, Paper} from "@mui/material";
import LineChart from '../charts/LineChart';
import StatisticCard from './StatisticCard';
import Linee from '../../assets/svg/Linee.svg';
import Path from '../../assets/svg/Path.svg';
import Days from '../../assets/svg/Days.svg';
import Road from '../../assets/svg/Road.svg';
import Bus from '../../assets/svg/Bus.svg';
import API from '../../API';

/* // Generate Sales Data
function createData(time, amount) {
    return { time, amount };
}

const data = [
    createData('00:00', 0),
    createData('03:00', 300),
    createData('06:00', 600),
    createData('09:00', 800),
    createData('12:00', 1500),
    createData('15:00', 2000),
    createData('18:00', 2400),
    createData('21:00', 2400),
    createData('24:00', undefined),
]; */

export default function DashboardPage() {
    const [busesNumber, setBusesNumber] = React.useState(0);
    const [pathsNumber, setPathsNumber] = React.useState(0);
    const [tripsNumber, setTripsNumber] = React.useState(0);
    const [daysNumber, setDaysNumber] = React.useState(0);
    const [totalKm, setTotalKm] = React.useState(0);

    React.useEffect(() => {
        API.getBusesNumber().then(buses => setBusesNumber(buses));
        API.getTotalDistances().then(distance => setTotalKm(distance));
        API.getPathsNumber().then(paths => setPathsNumber(paths));
        API.getTripsNumber().then(trips => setTripsNumber(trips));
        API.getDaysNumber().then(days => setDaysNumber(days));
    }, [])

    return (
        <Container>
        <Grid container spacing={4} sx={{px: 7}}>
            <Grid item xs={4}>
                <StatisticCard
                    title="Numero di linee analizzate"
                    count={tripsNumber}
                    imagePath={Linee}
                    percentage={59.3}
                    extra="35,000" />
            </Grid>
            <Grid item xs={4}>
                <StatisticCard
                    title="Numero di percorsi analizzati"
                    count={pathsNumber}
                    imagePath={Path}
                    percentage={59.3}
                    extra="35,000" />
            </Grid>
            <Grid item xs={4}>
                <StatisticCard
                    title="Numero di vetture analizzate"
                    count={busesNumber}
                    imagePath={Bus}
                    percentage={59.3}
                    extra="35,000" />
            </Grid>
            <Grid item xs={4}>
                <StatisticCard
                    title="Chilometri totali percorsi"
                    count={totalKm}
                    imagePath={Road}
                    percentage={59.3}
                    extra="35,000" />
            </Grid>
            <Grid item xs={4}>
                <StatisticCard
                    title="Numero di giorni analizzati"
                    count={daysNumber}
                    imagePath={Days}
                    percentage={59.3}
                    extra="35,000" />
            </Grid>
        </Grid>
   
        {/* <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
            <Grid container spacing={3}>
                <Grid item xs={12} md={8} lg={9}>
                    <Paper
                        sx={{
                            p: 2,
                            display: 'flex',
                            flexDirection: 'column',
                            height: 240,
                        }}
                    >
                        <LineChart
                            title="Today"
                            labelY="Sales"
                            data={data}
                            dataKeyX="time"
                            dataKeyY="amount"
                        />
                    </Paper>
                </Grid>
            </Grid>
        </Container> */}
        </Container>
    );
}
