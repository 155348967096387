import './FilterTool.css'
import Loading from '../../../utils/Loading';
import {Box, Button, Grid, TextField, Typography, Autocomplete, MenuItem, Divider} from "@mui/material";
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FilterAltRoundedIcon from '@mui/icons-material/FilterAltRounded';
import {LocalizationProvider} from '@mui/x-date-pickers-pro';
import {AdapterDayjs} from '@mui/x-date-pickers-pro/AdapterDayjs';
import {DatePicker} from '@mui/x-date-pickers/DatePicker';
import 'dayjs/locale/it';
import {useEffect, useState} from 'react'
import API from '../../../API';
import * as React from "react";

export default function FilterTool(props){
    const [error, setError] = useState(false)
    const [tripsName, setTripsName] = useState(new Map())
    const [loading, setLoading] = useState(true)
    const [currentTripValue, setCurrentTripValue] = useState('')

    const {setCurrentTrip, setDirty, singleDate, setSingleDate, filterDate, setFilterDate} = props;
    useEffect(()=> {
        API.getTripsName()
            .then((data) => {
                let names =  new Set([]);
                data.forEach( d => {
                    names.add(`${d.trip_name} (${d.shape_id})`)
                })
                setTripsName(names)
                setLoading(false)
            })
            .catch(err => console.log(err));
    }, []);

    const handleFilter = (e) => {
        if(currentTripValue === ""){
            setError(true)
        }else{
            setCurrentTrip(currentTripValue)
            setDirty(true)
        }
    }

    return (
        <> {loading
            ?
            <Loading loading={loading}/>
            :
            <>
            <Box id="filter">
                <FilterAltRoundedIcon />
                <Typography variant="h6" sx={{p: 0.5}}>
                    Esplora
                </Typography>
            </Box>
            <Grid
                container
                spacing={4}
                >
            
                <Grid item xs={12}>
                    <RadioGroup
                        aria-labelledby="date-label"
                        value={singleDate ? 'date' : 'all'}
                        name="radio-buttons-group"
                        onChange={(event) => {
                            event.target.value === 'all' ? setSingleDate(false) : setSingleDate(true);       
                        }}
                    >
                        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale='it'>
                            <MenuItem>
                                <FormControlLabel value="all" control={<Radio/>} label="Tutte le date"/>
                            </MenuItem>
                            <MenuItem>
                                <FormControlLabel value="date" control={<Radio/>} label="Data"/>
                                <DatePicker
                                    label="Date"
                                    value={filterDate}
                                    onChange={(newValue) => {
                                        setFilterDate(newValue);
                                    }}
                                    inputFormat="DD-MM-YYYY"
                                    disabled={!singleDate}
                                    renderInput={(params) => <TextField {...params} />}
                                />
                            </MenuItem>
                        </LocalizationProvider>
                    </RadioGroup>
                </Grid>
                <Grid item xs={12}> 
                    <Divider orientation="horizontal" flexItem/>
                </Grid>
                <Divider/>
                
                <Grid item xs={12}>
                    <Box display="flex" justifyContent="center">
                        <Autocomplete
                            disablePortal
                            id="combo-box-trip"
                            options={Array.from(tripsName)}
                            size="small"
                            sx={{ width: 290 }}
                            renderInput={(params) =>
                                <TextField
                                    {...params}
                                    label="Linea"
                                    helperText={error ? "Selezionare una linea" : ""}
                                    error={error}
                                />}
                            onChange={(event, newValue) => {
                                setError(false)
                                newValue != null ? setCurrentTripValue(newValue.toString().slice(newValue.toString().indexOf("(")+1, newValue.toString().indexOf(")"))) : setCurrentTripValue("");
                            }}
                        />

                    </Box>
                </Grid>

                <Grid item xs={12}> 
                    <Divider orientation="horizontal" flexItem/>
                </Grid>
                <Divider/>
                <Grid item xs={12}>
                    <Box display="flex" justifyContent="center">
                        <Button size="small" variant="contained" onClick={handleFilter}>Filtra</Button>
                    </Box>
                </Grid>
            </Grid>
            </>
        }</>
    )

}