import { useAuth0 } from "@auth0/auth0-react";
import { Button } from "@mui/material";
import React from "react";

export const LoginButton = () => {
  const { loginWithRedirect } = useAuth0();

  const handleLogin = async () => {
    await loginWithRedirect({
      prompt: "login",
      appState: {
        returnTo: "/dashboard",
      },
    });
  };

  return (
    <Button 
        size="big" 
        variant="contained"
        style={{minWidth: "10vw"}}
        onClick={handleLogin}
    >
        Accedi
    </Button>
  );
};
