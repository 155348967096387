import ATPSassari from '../../assets/ATPSassari.jpeg'
import './Homepage.css';
import OutlinedCard from "./HomepageCard";

const homePageStyle={
    backgroundImage: `url(${ATPSassari})`,
    height:'100vh',
    marginTop:'-60px',
    fontSize:'50px',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
};

function Homepage() {
    return (
        <div style={homePageStyle}>
            <div className="overlayCard">
                <OutlinedCard/>
            </div>
        </div>
    );
}

export default Homepage;