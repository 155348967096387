import { Typography } from "@mui/material";

export default function TripInformation(props){
    console.log(props.busIds)
    return(
    <>
       { props.busIds &&
        <Typography style={{ margin: '10px'}} variant="body1" component="div">
            Vetture che hanno percorso la linea: {Array.from(props.busIds).join(", ")}
        </Typography>
        }
        <Typography style={{ margin: '10px'}} variant="body1" component="div">
            Distanza media percorsa: {props.totalAverageDistance} km
        </Typography>
    </>    
    )
   
}