import LineChart from '../charts/LineChart';
import { Paper } from '@mui/material';
export default function SpeedChart(props){
    return(
        <Paper
            sx={{
                p: 2,
                display: 'flex',
                flexDirection: 'column',
                height: 280,
            }}
        >
            <LineChart
                title="Moto della vettura"
                labelY="velocità (m/s)"
                data={props.selectedPath.filter((el, idx) => idx % 20 === 0) //ogni 20 sec circa
                    .map(el => {
                        return {tempo: el.dateTimestamp.slice(11, 19), velocità: (parseFloat(el.speed)/3.6).toFixed(2)}
                    })
                }
                dataKeyX="tempo"
                dataKeyY="velocità"
            />
        </Paper>
    )
}
